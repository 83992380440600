import React from "react";
import Button from "../Inputs/Button";
import MaxContent from "../../layout/MaxContent";
import Markdown from '../Markdown/Markdown'
import { StrapiImage } from "../Types";
import { getTitleHTMLTag } from "../../helpers/tools";

interface BannerData {
  data: BannerProps;
}

interface BannerProps {
  Title: string;
  SubTitle?: string;
  FeaturedImage: StrapiImage;
  PrimaryButtonLabel: string;
  PrimaryButtonLink: string;
  SecondaryButtonLabel: string;
  SecondaryButtonUrl: string;
  PrimaryButtonNoFollow?: boolean;
  PrimaryButtonNewTab?: boolean;
  SecondaryButtonNoFollow?: boolean;
  SecondaryButtonNewTab?: boolean; SelectTitleType: {
    TitleTypeSelection: string;
  };
}

const PinkBanner: React.FC<BannerData> = ({ data }) => {
  const {
    Title,
    SubTitle,
    FeaturedImage,
    PrimaryButtonLabel,
    PrimaryButtonLink,
    SecondaryButtonLabel,
    SecondaryButtonUrl,
    PrimaryButtonNoFollow,
    PrimaryButtonNewTab,
    SecondaryButtonNoFollow,
    SecondaryButtonNewTab
  } = data;

  let title = getTitleHTMLTag(
    Title,
    data.SelectTitleType?.TitleTypeSelection,
    "text-3xl font-bold text-purple mb-3"
  );

  return (
    <MaxContent size="max">
      <div className=" bg-pink">
        <MaxContent size="content">
          <div className=" md:grid-cols-2 md:grid flex flex-col md:pt-20 md:pb-20  pt-12 pl-6 pr-6 md:px-0">
            <div className="text-left  mx-auto ">
              {Title && (
                <>
                  {title}
                </>
              )}
              {SubTitle && (
                <div className="md-content">
                  <Markdown>{SubTitle}</Markdown>
                </div>
              )}

              <div className="flex flex-col mt-3 justify-center space-y-5">
                {PrimaryButtonLabel && (
                  <Button
                    label={PrimaryButtonLabel}
                    href={PrimaryButtonLink || ""}
                    noFollow={PrimaryButtonNoFollow ?? false}
                    newTab={PrimaryButtonNewTab ?? false}
                  />
                )}
                {SecondaryButtonLabel && (
                  <Button
                    label={SecondaryButtonLabel}
                    href={SecondaryButtonUrl || ""}
                    secondary={true}
                    noFollow={SecondaryButtonNoFollow ?? false}
                    newTab={SecondaryButtonNewTab ?? false}
                  />
                )}
              </div>
            </div>
            {FeaturedImage?.file?.url && (
              <div className="flex items-center pb-10 md:pb-0">
                <img
                  src={FeaturedImage?.file?.url}
                  alt={FeaturedImage?.alternativeText}
                  className=" h-56 mx-auto object-contain"
                />
              </div>
            )}
          </div>
        </MaxContent>
      </div>
    </MaxContent>
  );
};
export default PinkBanner;
