import React, { useState } from "react";
// import { Button } from 'get-life-ts-components'
import { useDevice } from "../Hooks";
import MaxContent from "../../layout/MaxContent";
import Button from "../Headers/Button";
import {
  isGreaterThan0,
  isLessThan100,
  isLongerThan3,
  isNotNull,
} from "get-life-ts-components";
import { TextInput } from "get-life-ts-components";
import { NumberInput } from "get-life-ts-components";
import ReactMarkdown from "react-markdown";

interface StrapiData {
  data: ImageTextLeftProps;
}

interface ImageTextLeftProps {
  Title: string;
  FormTitle: string;
  FormContent: string;
  UseBanner?: boolean;
  SubItems: Array<FAQItemsProps>;
  ButtonsItems: Array<ButtonProps>;
}

const Contact_Form: React.FC<StrapiData> = ({ data }) => {
  let { Title, UseBanner, FormContent, FormTitle } = data;

  const { isMobile } = useDevice();
  const mobileBanner: boolean = UseBanner! && isMobile;
  const [Email, setEmail] = useState("");
  const [isEmailValidated, setIsEmailValidated] = useState(false);

  const [fullName, setFirstName] = useState("");
  const [isFullNameValidated, setIsFullNameValidated] = useState(false);

  const [IsPleasetellushowwecanhelp, setIsPleasetellushowwecanhelp] =
    useState("");
  const [
    isPleasetellushowwecanhelpValidated,
    setIsPleasetellushowwecanhelpValidated,
  ] = useState(false);

  const [PhoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValidated, setIsPhoneNumberValidated] = useState(false);

  return (
    <MaxContent size="content">
      <div>
        <div
          className={`${
            mobileBanner ? "" : "flex flex-col-reverse"
          }  md:grid-cols-2 md:grid grid gap-5 mt-8 pt-20 pb-20`}
        >
          {/* col 1 */}
          <div className="">
            <h2 className=" text-[25px] leading-[35px] lg:text-[35px] lg:leading-[45px] font-bold text-purple mb-3">
              {Title}{" "}
            </h2>

            {/* <ul className="my-4">
                {data.SubItems.map((item) => (
                  <FAQItems Question={item.Question} Answer={item.Answer} />
                ))}
              </ul> */}
          </div>

          {/* col 2 */}
          <div className=" ">
            <h2 className=" text-[25px] leading-[35px] lg:text-[35px] lg:leading-[45px] font-bold text-purple mb-3">
              {FormTitle}{" "}
            </h2>
            <TextInput
              query={"Full Name"}
              // supportText={"Your full name! Including any middle names"}
              placeholder={"Full Name"}
              validationChecks={[isNotNull, isLongerThan3]}
              onValidationChange={(value, isValidated) =>
                setIsFullNameValidated(isValidated)
              }
              onValueChange={(value) => setFirstName(value)}
            />
            <TextInput
              query={"Email*"}
              placeholder={"Email"}
              validationChecks={[isNotNull, isLongerThan3]}
              onValidationChange={(value, isValidated) =>
                setIsEmailValidated(isValidated)
              }
              onValueChange={(value) => setEmail(value)}
            />
            <NumberInput
              query={"Phone Number"}
              placeholder={"Phone Number"}
              validationChecks={[isNotNull, isGreaterThan0, isLessThan100]}
              onValidationChange={(value, isValidated) =>
                setIsPhoneNumberValidated(isValidated)
              }
              onValueChange={(value) => setPhoneNumber(value)}
            />
            <TextInput
              query={"Please tell us how we can help?"}
              placeholder={"Please tell us how we can help?"}
              validationChecks={[isNotNull, isLongerThan3]}
              onValidationChange={(value, isValidated) =>
                setIsPleasetellushowwecanhelpValidated(isValidated)
              }
              onValueChange={(value) => setIsPleasetellushowwecanhelp(value)}
            />
            <p className="text-[20px] leading-[30px] mt-3">
              <ReactMarkdown>{FormContent}</ReactMarkdown>
            </p>

            <div className="">
              {data.ButtonsItems?.map(
                (item: { Label: string; Url: string; Alt: string }) => (
                  <Buttons Label={item.Label} Url={item.Url} Alt={item.Alt} />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </MaxContent>
  );
};
interface ButtonProps {
  Label: string;
  Url: string;
  Alt: string;
}

const Buttons: React.FC<ButtonProps> = ({ Label, Url, Alt }) => {
  return (
    <div className="w-full md:max-w-[325px] mt-5 space-y-2">
      <Button
        label={Label}
        onClick={() => console.log("button 1 clicked")}
        secondary={true}
      />
    </div>
  );
};
interface FAQItemsProps {
  Question: string;
  Answer: string;
}

const FAQItems: React.FC<FAQItemsProps> = ({ Question, Answer }) => {
  return (
    <div className=" ">
      <h3 className="text-[32px] leading-[42px] font-bold text-purple mb-3 mt-5">
        {Question}{" "}
      </h3>
      <p className="text-[20px] leading-[30px]">{Answer}</p>
    </div>
  );
};
export default Contact_Form;
