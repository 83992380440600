import React from "react";

interface ButtonInterface {
  label: string;
  href: string
  secondary?: boolean;
  secondaryWithoutHover?: boolean;
  newTab?: boolean;
  noFollow?: boolean;
}

const Button: React.FC<ButtonInterface> = ({
  label,
  href,
  secondary,
  secondaryWithoutHover,
  noFollow,
  newTab
}) => {
  /*
   * using this component instead of: import { Button } from "get-life-ts-components";
   * for SEO crawling
  */

  const primaryStyle =
    "bg-purple text-white  border border-purple hover:!bg-white  hover:text-purple ";
  const secondaryStyle =
    "bg-white  text-purple border border-purple hover:border-white hover:!bg-purple hover:!text-white";
  const secondaryStyleWithoutHover =
    "bg-purple text-white border border-purple";

  return (
    <div className="flex w-full">
      <a
        href={href ?? '/'}
        className={`cursor-pointer disabled:bg-gray-300 disabled:border-gray-300  min-h-[60px] flex-grow text-[18px] font-bold opacity-100 transition-all hover:opacity-90  py-[16.5px] rounded-[5px] no-underline text-center
          ${ secondary
              ? secondaryStyle
              : secondaryWithoutHover
              ? secondaryStyleWithoutHover
              : primaryStyle
          } 
        `}
        rel={noFollow ? "nofollow" : undefined}
        target={newTab ? "_blank": undefined}
        >{label}</a>
    </div>
  );
};

export default Button;