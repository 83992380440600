import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { getTitleHTMLTag } from "../../helpers/tools";
import { Button } from "get-life-ts-components";
import MaxContent from "../../layout/MaxContent";
import { FreeMode, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import TrustPilotStars from "./TrustPilotStars";

const Star =
  "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/TrustPilot/TrustPilotStar.svg";
interface TrustPilotData {
  data: TrustPilotCarouselProps;
}
interface TrustPilotCarouselProps {
  Title: string;
  SelectTitleType: {
    TitleTypeSelection: string;
  };
  TotalReviews: number;
  Score: number;
  LeftMessage: string;
  RightMessage: string;
  ReviewCard: TrustPilotReview[];
  LinkToReviews?: { Label: string; Url?: string; Secondary?: boolean };
}
interface TrustPilotReview {
  Stars: number;
  Review: string;
  Date: string;
  FullName: string;
}

const TrustPilot: React.FC<TrustPilotData> = ({ data }) => {
  let title = getTitleHTMLTag(
    data.Title,
    data.SelectTitleType?.TitleTypeSelection,
    "font-bold text-purple text-[30px] leading-[42px] md:text-[35px] md:leading[45px] mb-[30px]"
  );

  return (
    <MaxContent size="full" className="bg-[#DAF3E9]">
      <MaxContent size="content">
        <div className="flex flex-col justify-center items-center w-full ">
          <div className="flex flex-col justify-around mb-5 mt-[100px]">
            {data.Title && title}

            <div className="flex flex-col sm:flex-row justify-center mb-[20px]">
              <p className="font-DM mr-[15px]">
                {data.TotalReviews && (
                  <span className="font-RW font-bold">
                    {data.TotalReviews} reviews{" "}
                  </span>
                )}
                {data.Score && "| "}
                {data.Score && (
                  <span>
                    <span className="font-RW font-bold">{data.Score}</span> on
                    Trustpilot
                  </span>
                )}
              </p>
              <TrustPilotStars />
            </div>
          </div>

          <Swiper
            slidesPerView={1.2}
            spaceBetween={15}
            breakpoints={{
              500: {
                slidesPerView: 1.8,
              },

              // when window width is >= 640px
              760: {
                slidesPerView: 2.5,
              },
              1120: {
                slidesPerView: 3.5,
              },
            }}
            freeMode={true}
            pagination={{
              clickable: true,
              el: ".swiper-custom-pagination",
            }}
            modules={[FreeMode, Pagination]}
            className="!h-[310px] w-full md:w-4/5"
          >
            {data.ReviewCard.map((review: TrustPilotReview, index: number) => {
              return (
                <SwiperSlide key={review.FullName} className="">
                  <ReviewCard {...review} />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="swiper-custom-pagination w-full md:w-4/5 mt-5 cursor-pointer flex flex-row justify-center mb-[40px] gap-[5px]" />
          <div className="w-[325px] mx-auto mt-5 mb-[80px]">
            {data.LinkToReviews?.Label && (
              <Button
                label={data.LinkToReviews.Label}
                onClick={() => {
                  if (typeof window !== "undefined") {
                    window.open(`${data.LinkToReviews?.Url}`, "_blank");
                  }
                }}
                secondary={data.LinkToReviews.Secondary}
              />
            )}
          </div>
        </div>
      </MaxContent>
    </MaxContent>
  );
};

const ReviewCard: React.FC<TrustPilotReview> = (review) => {
  return (
    <div className="flex flex-col items-start justify-between h-full py-[50px] px-[30px]">
      <TrustPilotStarsReview rating={review.Stars} />
      <p className="text-body-sm text-left overflow-y-auto hide-scroll my-[20px]">
        {review.Review}
      </p>

      <div className="flex flex-col text-left">
        <p className="font-MW font-bold text-[17px] leading-[27px]">
          {review.FullName}
        </p>
        <p className="text-body-sm "> {review.Date} </p>
      </div>
    </div>
  );
};

const TrustPilotStarsReview = ({ rating }: any) => {
  // create empty array len 5 and iterate over it

  const stars = Array.from(Array(rating)).map((_, index) => {
    return (
      <div key={index} className={"p-0.5 w-5 inline-block mr-0.5 bg-[#00B67A]"}>
        <img src={Star} className="w-full" alt="Trustpilot" />
      </div>
    );
  });
  return <div className="flex items-center">{stars}</div>;
};

export default TrustPilot;
