import React from "react";
import MaxContent from "../../layout/MaxContent";
import { Link } from "gatsby";

import { useBreadCrumbs } from "../Hooks";

interface StrapiData {
  data: HeaderProps;
}

interface HeaderProps {
  Title: string;
}

const TertiaryHeader: React.FC<StrapiData> = ({ data }) => {
  const { breadcrumbs } = useBreadCrumbs();

  return (
    <MaxContent size="full">
      <div className="bg-inactive-gray w-full mb-[50px] ">
        <MaxContent size="content">
          <div className="flex flex-col-reverse w-full  bg-inactive-gray items-start">
            <div className="text-left w-full  mt-2 md:mt-12 ">
              <div className="flex-row mb-10 hidden md:flex">
                {breadcrumbs.map((v, i) => {
                  if (i < breadcrumbs.length - 1) {
                    return (
                      <div className="flex flex-row " key={i}>
                        <Link
                          to={v.link}
                          className={"text-[14px] leading-[18px] font-normal text-placeholder mb-3 w-full md:w-auto no-underline"}
                          key={i}>
                          <span>{v.name}</span>
                        </Link>
                        <span className={"text-[14px] leading-[18px] px-2.5 text-placeholder font-normal"}>
                          |
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <span key={i} className={"text-[14px] leading-[18px] font-bold text-black"}>
                        {v.name}
                      </span>
                    );
                  }
                })}
              </div>

              {data?.Title && (
                <h1 className={`text-purple w-full md:w-3/4 md:mb-[60px] my-12 md:mt-0`}>
                  {data.Title}
                </h1>
              )}
            </div>
          </div>
        </MaxContent>
      </div>
    </MaxContent>
  );
};

export default TertiaryHeader;