import React from "react";
import IconsRow from "../components/IconsRow/IconsRow";
import ProductCardsRow from "../components/ProductCardsRow/ProductCardsRow";
import PromoBanner from "../components/Banners/PromoBanner";
import LegalTable from "../components/Tables/LegalTable/LegalTable";
import CompareTable from "../components/Tables/CompareTable/CompareTable";
import Accordion from "../components/Accordion/Accordion";
import TrustPilot from "../components/TrustPilot/TrustPilot";
import CompareWith from "../components/CompareWith/CompareWith";
import USPIconRow from "../components/USPIconRow/USPIconRow";
import ImageBanner from "../components/Banners/ImageBanner";
import CardsContainer from "../components/Featured_Cards/FeaturedCards";
import Feature_Content from "../components/Feature_Content/Feature_Content";
import Header from "../components/Headers/Header";
import ProductCarousel from "../components/Carousel/ProductCarousel";
import WhatsIncluded from "../components/Whats_Included/WhatsIncluded";
import FeaturedArticles from "../components/Articles/FeaturedArticles";
import Navigation_Header from "../components/Headers/Navigation_Header";
import PinkBanner from "../components/Banners/PinkBanner";
import PolicyCards from "../components/PolicyCards/PolicyCards";
import Contact_Form from "../components/Contact_Form/Contact_Form";
import Faq from "../components/FAQ/Faq";
import AnimatedImageText from "../components/AnimatedImageText/AnimatedImageText";
import SecondaryHeader from "../components/Headers/SecondaryHeader";
import Text from "../components/Basic/Text";
import Title from "../components/Basic/Title";
import TitleText from "../components/Basic/TitleText";
import Image from "../components/Basic/Image";
import Button from "../components/Basic/Button";
import TertiaryHeader from "../components/Headers/TertiaryHeader";
import Hubspot from "../components/Hubspot/Hubspot";

const ComponentMap: any = {
  StrapiComponentComponentPromoBanner: (v: any) => (
    <PromoBanner data={v} key={v.id} />
  ),
  StrapiComponentComponentLegalTable: (v: any) => (
    <LegalTable data={v} key={v.id} />
  ),
  StrapiComponentComponentCompareTable: (v: any) => (
    <CompareTable data={v} key={v.id} />
  ),
  StrapiComponentComponentAccordion: (v: any) => (
    <Accordion data={v} key={v.id} />
  ),
  StrapiComponentComponentTrustPilotCarousel: (v: any) => (
    <TrustPilot data={v} key={v.id} />
  ),
  StrapiComponentComponentCompareWith: (v: any) => (
    <CompareWith data={v} key={v.id} />
  ),
  StrapiComponentComponentUspIcons: (v: any) => (
    <USPIconRow data={v} key={v.id} />
  ),
  StrapiComponentComponentIconsRow: (v: any) => (
    <IconsRow data={v} key={v.id} />
  ),
  StrapiComponentComponentProductCardsRow: (v: any) => (
    <ProductCardsRow data={v} key={v.id} />
  ),
  StrapiComponentComponentImageBanner: (v: any) => (
    <ImageBanner data={v} key={v.id} />
  ),
  StrapiComponentComponentFeaturedCards: (v: any) => (
    <CardsContainer data={v} key={v.id} />
  ),
  StrapiComponentComponentFeatureContent: (v: any) => (
    <Feature_Content data={v} key={v.id} />
  ),
  StrapiComponentComponentWhatsIncluded: (v: any) => (
    <WhatsIncluded data={v} key={v.id} />
  ),
  StrapiComponentComponentHeader: (v: any) => <Header data={v} key={v.id} />,
  StrapiComponentComponentSecondaryHeader: (v: any) => (
    <SecondaryHeader data={v} key={v.id} />
  ),
  StrapiComponentComponentTertiaryHeader: (v: any) => (
    <TertiaryHeader data={v} key={v.id} />
  ),
  StrapiComponentComponentAnimatedLines: (v: any) => (
    <AnimatedImageText data={v} key={v.id} />
  ),

  StrapiComponentComponentProductCarousel: (v: any) => (
    <ProductCarousel data={v} key={v.id} />
  ),
  StrapiComponentComponentNavigationHeader: (v: any) => (
    <Navigation_Header data={v} key={v.id} />
  ),
  StrapiComponentComponentFeaturedArticles: (v: any) => (
    <FeaturedArticles data={v} key={v.id} />
  ),
  StrapiComponentComponentPinkBanner: (v: any) => (
    <PinkBanner data={v} key={v.id} />
  ),
  StrapiComponentComponentContactForm: (v: any) => (
    <Contact_Form data={v} key={v.id} />
  ),
  StrapiComponentComponentPolicyCards: (v: any) => (
    <PolicyCards data={v} key={v.id} />
  ),
  StrapiComponentComponentFaqListings: (v: any) => <Faq data={v} key={v.id} />,
  StrapiComponentBasicTitle: (v: any) => <Title data={v} key={v.id} />,
  StrapiComponentBasicText: (v: any) => <Text data={v} key={v.id} />,
  StrapiComponentBasicImage: (v: any) => <Image data={v} key={v.id} />,
  StrapiComponentBasicTitleText: (v: any) => <TitleText data={v} key={v.id} />,
  StrapiComponentBasicButton: (v: any) => <Button data={v} key={v.id} />,
  StrapiComponentComponentHubspotForm: (v: any) => <Hubspot data={v} key={v.id} />
};

export const componentBuilder = (strapiComponents: any[]) => {
  const components = strapiComponents.map((component) => {
    let itemsResult = undefined;

    try {
      // find matching component type
      let builderFn = ComponentMap[component.__typename];
      // call build function to a JSX component
      itemsResult = builderFn(component);
    } catch {
      (e: any) => console.error(e);
    }

    if (itemsResult === undefined) {
      return <></>;
    }
    return itemsResult;
  });
  return components;
};
