import React from "react";
import MaxContent from "../../layout/MaxContent";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from '../Inputs/Button'
import TrustPilotStars from "../TrustPilot/TrustPilotStars";
import { Link } from "gatsby";
import { HeaderButtonType, HeaderPoint, HeaderPointProps } from "./Header";
import { useBreadCrumbs } from "../Hooks";
import TrustPilotStaticWidget from "../TrustPilot/TrustPilotStaticWidget";
import Markdown from '../Markdown/Markdown'

interface StrapiData {
  data: HeaderProps;
}

interface HeaderProps {
  Title: string;
  Subtitle: string;
  Text: string;
  SecHeaderPoints: HeaderPointProps[];
  SecHeaderButtons: HeaderButtonType[];
  TrustPilotScore: string;
  Trustpilot: boolean;
  ExcellentText: string;
  ExcellentLink: string;
  SecHeaderImage: {
    alternativeText: string;
    file: {
      childImageSharp: {
        gatsbyImageData: any;
      };
    };
  };
}

const SecondaryHeader: React.FC<StrapiData> = ({ data }) => {
  const { breadcrumbs } = useBreadCrumbs();

  return (
    <MaxContent size="full">
      <div className="bg-inactive-gray w-full">
        <MaxContent size="max">
          <div className="flex flex-col-reverse md:grid-cols-2 md:grid ">
            {/* <MaxContent size="content-inner"> */}
            <div className="my-[60px] px-[25px] md:ml-auto md:pr-24  max-w-[560px]  w-full ">
              <div className="hidden md:flex flex-row mb-10">
                {breadcrumbs.map((v, i) => {
                  if (i < breadcrumbs.length - 1) {
                    return (
                      <div className="flex flex-row" key={i}>
                        <Link
                          to={v.link}
                          className={
                            "text-[14px] leading-[18px] font-normal text-placeholder mb-3 w-full md:w-auto no-underline"
                          }
                          key={i}
                        >
                          <span>{v.name}</span>
                        </Link>
                        <span
                          className={
                            "text-[14px] leading-[18px] px-2.5 text-placeholder font-normal"
                          }
                        >
                          |
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <span
                        className={"text-[14px] leading-[18px] font-black"}
                        key={i}
                      >
                        {v.name}
                      </span>
                    );
                  }
                })}
              </div>

              {data.Title && (
                <h1 className="text-purple mb-2 md:mb-7 w-full md:w-auto mt-10 md:mt-0">
                  {data.Title}{" "}
                </h1>
              )}
              {data.Subtitle && (
                <div className="h4-default text-purple mb-2 lg:mb-4 md-content">
                  <Markdown>{data.Subtitle}</Markdown>
                </div>
              )}
              {data.Text && (
                <div className="text-placeholder mb-2 lg:mb-3 md-content">
                  <Markdown>{data.Text}</Markdown>
                </div>
              )}

              <ul className="flex flex-col md:flex-col font-normal gap-2 md:gap-3 mt-5 lg:mt-4">
                {data.SecHeaderPoints.map((point, i) => (
                  <HeaderPoint Point={point.Point} key={`header-point-${i}`} />
                ))}
              </ul>

              <div
                className={`flex flex-col mt-5 md:mt-7 ${
                  data.Trustpilot === true ? "mb-5" : "mb-20"
                } md:mb-10 md:max-w-[325px]`}
              >
                {data.SecHeaderButtons.length > 0 &&
                  data.SecHeaderButtons.map((btn) => (
                    <div className="mb-2" key={btn.Label}>
                      <Button
                        label={btn.Label}
                        href={btn.Url ?? '/'}
                        secondary={btn.Secondary}
                        noFollow={btn.NoFollow}
                        newTab={btn.NewTab}
                      />
                    </div>
                  ))}
              </div>

              {data.Trustpilot && (
                <div className="mb-[60px] md:mb-[70px]">
                  <TrustPilotStaticWidget score={data?.TrustPilotScore} excellentText={ data?.ExcellentText } excellentLink={ data?.ExcellentLink }/>
                  <div className="flex items-left justify-left ">
                    <TrustPilotStars />
                  </div>
                </div>
              )}
            </div>
            {/* </MaxContent> */}

            <div className="flex items-center">
              <GatsbyImage
                image={data.SecHeaderImage?.file?.childImageSharp?.gatsbyImageData}
                alt={data.SecHeaderImage?.alternativeText}
                className="w-full h-full"
              />
            </div>
          </div>
        </MaxContent>
      </div>
    </MaxContent>
  );
};
export default SecondaryHeader;
