import React, { useEffect, useState } from "react";
import Markdown from '../Markdown/Markdown'
import MaxContent from "../../layout/MaxContent";
import Button from '../Inputs/Button'
import { StrapiRichText, StrapiImage } from "../Types";
import { useDevice } from "../Hooks";
import { getTitleHTMLTag } from "../../helpers/tools";
import { StrapiTitleSelect } from '../Types'

interface CompareWithData {
  data: CWProps;
}

interface CWProps extends StrapiTitleSelect {
  Title: string;
  SubTitle: string;
  Items: Condition[];
}

interface Condition {
  Condition: string;
  LeftIcon: StrapiImage;
  LeftTitle: string;
  LeftText: StrapiRichText;
  LeftButtonLabel: string;
  LeftButtonUrl: string;
  LeftButtonNoFollow: boolean;
  LeftButtonNewTab: boolean;

  RightIcon: StrapiImage;
  RightTitle: string;
  RightText: StrapiRichText;
  RightButtonLabel: string;
  RightButtonUrl: string;
  RightButtonNoFollow: boolean;
  RightButtonNewTab: boolean;
}

const CompareWith: React.FC<CompareWithData> = ({ data }) => {
  const [selected, setSelected] = useState<Condition>(data.Items[0] || []);
  const { isMobile } = useDevice();

  let left: WWCardProps = {
    Icon: selected.LeftIcon,
    Title: selected.LeftTitle,
    Text: selected.LeftText,
    ButtonLabel: selected.LeftButtonLabel,
    ButtonUrl: selected.LeftButtonUrl,
    ButtonNoFollow: selected.LeftButtonNoFollow,
    ButtonNewTab: selected.LeftButtonNewTab
  };

  let right: WWCardProps = {
    Icon: selected.RightIcon,
    Title: selected.RightTitle,
    Text: selected.RightText,
    ButtonLabel: selected.RightButtonLabel,
    ButtonUrl: selected.RightButtonUrl,
    ButtonNoFollow: selected.RightButtonNoFollow,
    ButtonNewTab: selected.RightButtonNewTab
  };

  let title = getTitleHTMLTag(data.Title, data.SelectTitleType?.TitleTypeSelection, 'text-[35px] leading-[45px] text-purple' )

  return (
    // ADD CLASSNAME & MARGIN BOTTOM -10px
    <div className="mb-[-10px]">
      <MaxContent size={"content"}>
        <div className="flex flex-col items-center mb-10 gap-10">
          <div className="w-full h-1 bg-purple my-10" />

          <div className="flex flex-col items-center">
            {data.Title && title }

            {data.SubTitle && (
                <Markdown>{data.SubTitle}</Markdown>
            )}
          </div>
          {data.Items && (
            <SelectConditionGroup
              items={data.Items}
              onValueChange={setSelected}
            />
          )}
        </div>
      </MaxContent>
      <MaxContent size={isMobile ? "max" : "content"}>
        <div className="grid grid-rows-1 md:grid-cols-2 mb-2">
          <WithWithoutCard {...left} />

          <WithWithoutCard {...right} alt={true} />
        </div>
      </MaxContent>
    </div>
  );
};

interface WWCardProps {
  Icon: StrapiImage;
  Title: string;
  Text: StrapiRichText;
  ButtonLabel: string;
  ButtonUrl: string;
  ButtonNoFollow: boolean;
  ButtonNewTab: boolean;
  alt?: boolean;
}

const WithWithoutCard: React.FC<WWCardProps> = ({
  Icon,
  Title,
  Text,
  ButtonLabel,
  ButtonUrl,
  ButtonNoFollow,
  ButtonNewTab,
  alt,
}) => {
  return (
    <div
      //cs changed my-[50] to to mb-[50] bug 327
      className={`flex flex-col justify-between rounded-sm    mb-[0px] md:mb-[80px]  ${
        alt ? "bg-inactive-gray" : "bg-purple"
      }`}
    >
      <div className="p-10">
        <div className="flex flex-col gap-6">
          <div className="w-[120px] h-[120px] flex  items-center mx-auto">
            {Icon?.file?.url && (
              <img
                src={Icon?.file?.url}
                alt={Icon?.alternativeText}
                className="mx-auto "
              />
            )}
          </div>

          <h2
            className={`h2-lg text-[35px] leading-[45px] font-bold text-left ${
              alt ? "text-purple" : "text-white"
            }`}
          >
            {Title}
          </h2>

          <ul
            className={`${
              alt ? "compare-content-alt" : "compare-content text-white"
            }`}
          >
            <Markdown>{Text}</Markdown>
          </ul>
        </div>
      </div>

      {ButtonLabel && (
        <div className=" w-72 object-contain pl-10 pb-10">
          <Button
            label={ButtonLabel || ""}
            href={ButtonUrl ?? "/"}
            secondary={true}
            noFollow={ButtonNoFollow}
            newTab={ButtonNewTab}
          />
        </div>
      )}
    </div>
  );
};

interface SelectConditionProps {
  items: Condition[];
  onValueChange?: React.Dispatch<React.SetStateAction<Condition>>;
}

const SelectConditionGroup: React.FC<SelectConditionProps> = ({
  items,
  onValueChange,
}) => {
  const [selected, setSelected] = useState<Condition>(items[0]);

  useEffect(() => {
    if (onValueChange !== undefined) {
      onValueChange(selected);
    }
  }, [selected]);

  return (
    <div className="flex flex-row justify-center gap-1 w-full">
      {items.map((c: Condition) => (
        <SelectOption
          option={c}
          selected={selected}
          setSelected={setSelected}
        />
      ))}
    </div>
  );
};

interface SelectOptProps {
  option: Condition;
  selected: Condition;
  setSelected: (v: Condition) => void;
}

const SelectOption: React.FC<SelectOptProps> = ({
  selected,
  setSelected,
  option,
}) => {
  return (
    <label
      className={`flex flex-col items-center  radio !shadow-none !bg-white
      h-full max-w-[155px] !p-0
      text-purple overflow-hidden font-medium cursor-pointer
      ${selected.Condition === option.Condition ? "!border-purple" : ""}
    `}
    >
      <input
        type="radio"
        className="hidden"
        onClick={() => setSelected(option)}
      />

      <div
        className={`flex justify-center items-center px-[15px] py-[12px]${" "}
        w-full text-[15px] text-center font-bold leading-[18px]${" "}
        bg-white overflow-hidden transition${" "}
        ${
          selected.Condition === option.Condition
            ? "!bg-purple !border-purple !text-white"
            : ""
        }`}
      >
        <p>{option.Condition}</p>
      </div>
    </label>
  );
};

export default CompareWith;
