import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const logo = "https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.svg";

interface SeoDataInterface {
  data: SEO;
  locale: string;
  prev?: string;
  next?: string;
  canonical?: any;
}

type SeoLink = {
  Href: string;
  HrefLang: string;
};

export type SEO = {
  SeoTitle: string;
  SeoDescription: string;
  Schema: string;
  CanonicalTag: string;
  DoNotIndex?: boolean;
  DoNotFollow?: boolean;
  Links: Array<SeoLink>;
  Image?: any;
};

const SeoHelmet: React.FC<SeoDataInterface> = ({
  data,
  locale,
  prev,
  next,
  canonical,
}) => {
  const [url, setUrl] = useState(data.CanonicalTag);

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  useEffect(() => {
    if (data.CanonicalTag) {
      setUrl(data.CanonicalTag);
    }
  }, [{ ...data }]);

  const getMetaRobots = () => {
    const content: string[] = [];

    if (data?.DoNotIndex !== null) {
      if (data.DoNotIndex) {
        content.push("noindex");
      } else {
        content.push("index");
      }
    }

    if (data?.DoNotFollow !== null) {
      if (data.DoNotFollow) {
        content.push("nofollow");
      } else {
        content.push("follow");
      }
    }

    if (data?.DoNotIndex === null && data?.DoNotFollow === null) {
      return <meta name="robots" content="index,follow"></meta>;
    } else {
      const contentString =
        content.length === 0 ? null : content.reduce((a, b) => a + "," + b);
      return contentString === null ? null : (
        <meta name="robots" content={contentString}></meta>
      );
    }
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: locale }}>
        <link rel="canonical" href={url} />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {prev && <link rel="prev" href={prev} />}
        {next && <link rel="next" href={next} />}

        {data && getMetaRobots()}

        {data && data.SeoTitle ? (
          <title>{data.SeoTitle}</title>
        ) : (
          <title>Get Life Insurance</title>
        )}
        {data && data.SeoDescription ? (
          <meta name="description" content={data.SeoDescription} />
        ) : (
          <meta
            name="description"
            content="Retrouvez sur notre blog Life5 toutes les actualités et Informations sur l'assurance décès invalidité et la prévoyance en France."
          />
        )}
        {data && data.Image ? (
          <meta name="image" content={data.Image.url} />
        ) : (
          <meta name="image" content={logo} />
        )}

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        {data && data.SeoTitle ? (
          <meta name="twitter:title" content={data.SeoTitle} />
        ) : (
          <meta name="twitter:title" content="Get Life Insurance" />
        )}
        {data && data.SeoDescription ? (
          <meta name="twitter:description" content={data.SeoDescription} />
        ) : (
          <meta
            name="twitter:description"
            content="This is the Get Life website."
          />
        )}
        {data && data.Image ? (
          <meta name="twitter:image" content={data.Image.url} />
        ) : (
          <meta name="twitter:image" content={logo} />
        )}

        {/* FB & Instagram */}
        <meta name="og:card" content="summary_large_image" />
        {data && data.SeoTitle ? (
          <meta name="og:title" content={data.SeoTitle} />
        ) : (
          <meta name="og:title" content="Get Life Insurance" />
        )}
        {data && data.SeoDescription ? (
          <meta name="og:description" content={data.SeoDescription} />
        ) : (
          <meta name="og:description" content="This is the Get Life website." />
        )}
        {data && data.Image ? (
          <meta name="og:image" content={data.Image.url} />
        ) : (
          <meta name="og:image" content={logo} />
        )}
        <meta name="facebook-domain-verification" content="0chjdwo9wb62q1e5ym3g8a1gxdy6pu" />
        
        <script src="https://www.googleoptimize.com/optimize.js?id=OPT-T28R843"></script>
        {data && data.Schema && (
          <script type="application/ld+json">
            {JSON.stringify(data.Schema)}
          </script>
        )}

        {data &&
          data.Links &&
          data.Links.map((link: SeoLink) => (
            <link rel="alternative" hrefLang={link.HrefLang} href={link.Href} />
          ))}
      </Helmet>
    </>
  );
};

export default SeoHelmet;
