import React from "react";
import MaxContent from "../../layout/MaxContent";
import { useStaticQuery, graphql, Link } from "gatsby";
import Button from '../Inputs/Button'
import { useDevice } from "../Hooks";
import { IBlog, ColourCategoryMap } from "./Article_Listing/ArticleListing";
import MakeSlug from '../../helpers/makeSlug'
import { GatsbyImage } from 'gatsby-plugin-image'

interface StrapiData {
  data: IFeaturedArticles;
}

interface IFeaturedArticles {
  Title: string;
  ButtonLabel: string
  ButtonUrl: string
  NoFollow?: boolean
  NewTab?: boolean
}

const FeaturedArticles: React.FC<StrapiData> = ({ data }) => {
  const { isMobile, isMobileTablet } = useDevice();
  const query = useStaticQuery(BlogQuery);
  const blogs = query.allStrapiBlog.nodes;
  const latest = blogs
    .sort((a: IBlog, b: IBlog) => new Date(a.publishedAt).valueOf() - new Date(b.publishedAt).valueOf())
    .reverse();

  return (
    <MaxContent size="content">
      <div className="flex flex-col items-center pt-20 pb-20">
        <h3 className="font-bold text-[26px] leading-[36px] lg:text-[35px] lg:leading-[45px] text-purple">
          {data.Title}
        </h3>

        <div className=" flex flex-col md:flex-row   gap-[30px] pt-4">
          {latest &&
            latest
              .slice(0, isMobileTablet ? 3 : 4)
              .map((blog: IBlog, index: number) => {
                if (isMobile) {
                  if (index < 3) {
                    return <FeaturedArticlesBlog {...blog} />;
                  }
                } else {
                  return <FeaturedArticlesBlog {...blog} />;
                }
              })}
        </div>

        <div className="w-[325px] h-[60px] mt-[80px]">
          <Link to={data.ButtonUrl ?? '/blog'} className="no-underline">
            <Button
              label={data.ButtonLabel}
              href={data.ButtonUrl ?? '/blog'}
              secondary={true}
              noFollow={data.NoFollow ?? false}
              newTab={data.NewTab ?? false}
            />
          </Link>
        </div>
      </div>
    </MaxContent>
  );
};

export const FeaturedArticlesBlog = (blog: IBlog) => {
  let url = `/blog/${blog.CustomUrl ?? MakeSlug(blog.Title)}`;

  if (blog.Category) {
    url = `/blog/${MakeSlug(blog.Category.toLowerCase().replace(/_/g, '-'))}/${blog.CustomUrl ?? MakeSlug(blog.Title)}`;
  }

  if (blog.Category === "Conseil_et_fiches_pratiques") {
    url = `/blog/conseils/${blog.CustomUrl ?? MakeSlug(blog.Title)}`;
  }

  if (blog.Category === "Edito_et_actus") {
    url = `/blog/edito/${blog.CustomUrl ?? MakeSlug(blog.Title)}`;
  }

  return (
    <div className="flex flex-row md:flex-col md:w-full min-h-[94px] md:h-full pt-5">
      <GatsbyImage
        className="min-w-[190px] max-w-[190px] md:max-w-full h-full md:h-[243px] mb-[30px] rounded-md"
        image={blog.PrimaryImage?.file?.childImageSharp?.gatsbyImageData}
        alt={blog.PrimaryImage?.alternativeText || ''}
      />

      <div className="flex flex-col ml-[11px] md:ml-0">
        <div
          className="rounded-full w-fit font-MW font-bold text-[10px] leading-[12.5px] text-white px-[8px] py-[3px] md:mb-[20px]"
          style={{ backgroundColor: ColourCategoryMap[blog.Category]?.hex || ColourCategoryMap.default.hex }} >
          {blog.Category && blog.Category.toUpperCase().replaceAll("_", " ")}
        </div>

        <Link to={url} className="group no-underline text-placeholder hover:text-purple cursor-pointer">
          <p className="text-link-lg font-bold font-DM group-hover:underline">
            {blog.Title}
          </p>
        </Link>
      </div>
    </div>
  );
};

const BlogQuery = graphql`
  query {
    allStrapiBlog {
      nodes {
        Title
        Category
        Description
        createdAt
        updatedAt
        CustomUrl
        PrimaryImage {
          alternativeText
          file {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default FeaturedArticles;
