import React from "react";
import MakeSlug from "../../../helpers/makeSlug";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { limitChar } from "../../../helpers/tools";
import { StrapiImage } from "../../Types";

export interface IBlog {
  Title: string;
  Description: string;
  CustomUrl: string
  Category: string;
  Content: any;
  createdAt: any;
  updatedAt: any;
  publishedAt: any;
  PrimaryImage: StrapiImage;
}

type Category =
  | "Todos" // all
  | "Fiscalidad" // tax
  | "Actualidad" // present
  | "Ahorro" // savings
  | "Seguros" // insurance
  | "Educacion_financiera" // financial education
  | "Life_Talks"
  | 'Toutes'
  | 'Conseil_et_fiches_pratiques'
  | 'Decryptage'
  | 'Edito_et_actus'
  | 'Life5'
  | 'Tech';

  export const categoryList:Record<string, any> = {
    es: [
      "Todos",
      "Fiscalidad",
      "Actualidad",
      "Ahorro",
      "Seguros",
      "Educacion_financiera",
      "Life_Talks",
    ],
    fr: [
      "Toutes",
      "Conseil_et_fiches_pratiques",
      "Decryptage",
      "Edito_et_actus",
      "Life5",
      "Tech",
    ],
    pt: [
      "Toutes",
      "Conseil_et_fiches_pratiques",
      "Decryptage",
      "Edito_et_actus",
      "Life5",
      "Tech",
    ],
    it: [
      "Toutes",
      "Conseil_et_fiches_pratiques",
      "Decryptage",
      "Edito_et_actus",
      "Life5",
      "Tech",
    ]
  }

export const ColourCategoryMap: Record<Category | string, { hex: string; colour: string }> = {
  Fiscalidad: { hex: "#E9C002", colour: "yellow" },
  Actualidad: { hex: "#FF6B00", colour: "orange" },
  Ahorro:     { hex: "#F4C7DD", colour: "pink"   },
  Seguros:    { hex: "#0000FF", colour: "blue"   },
  Educacion_financiera: { hex: "#018565", colour: "green" },
  Life_Talks: { hex: "#0000FF", colour: "blue"   },
  Conseil_et_fiches_pratiques: { hex: "#E9C002", colour: "yellow" },
  Decryptage: { hex: "#FF6B00", colour: "orange" },
  Edito_et_actus:     { hex: "#F4C7DD", colour: "pink"   },
  Life5:    { hex: "#0000FF", colour: "blue"   },
  Tech: { hex: "#018565", colour: "green" },
  default: { hex: "#FFFFFF", colour: "white" },
};

export const Blog = (blog: IBlog) => {
  let url = `/blog/${blog.CustomUrl ?? MakeSlug(blog.Title)}`;

  if (blog.Category) {
    url = `/blog/${MakeSlug(blog.Category.toLowerCase().replace(/_/g, '-'))}/${blog.CustomUrl ?? MakeSlug(blog.Title)}`;
  }

  if (blog.Category === "Conseil_et_fiches_pratiques") {
    url = `/blog/conseils/${blog.CustomUrl ?? MakeSlug(blog.Title)}`;
  }

  if (blog.Category === "Edito_et_actus") {
    url = `/blog/edito/${blog.CustomUrl ?? MakeSlug(blog.Title)}`;
  }


  return (
    <div className="flex flex-row md:flex-col min-h-[94px] md:h-full pt-5">
      <Link to={url}>
        <GatsbyImage
          className="min-w-[190px] max-w-[190px] md:max-w-full h-full md:h-[243px] mb-[30px] rounded-md"
          image={blog.PrimaryImage?.file?.childImageSharp?.gatsbyImageData}
          alt={blog.PrimaryImage?.alternativeText || ''} />
      </Link>

      <div className="flex flex-col justify-center ml-[11px] md:ml-0">
        <div
          className="rounded-full w-fit font-MW font-bold text-[10px] leading-[12.5px] text-white px-[8px] py-[3px] md:mb-[20px]"
          style={{ backgroundColor: ColourCategoryMap[blog.Category]?.hex || ColourCategoryMap.default.hex }} >
          {blog.Category && blog.Category.toUpperCase().replaceAll("_", " ")}
        </div>

        <Link to={url} className="group no-underline text-placeholder hover:text-purple cursor-pointer">
          <h3 className="text-link-lg font-bold font-DM group-hover:underline">
            {limitChar(blog.Title, 52)}
          </h3>
          <p className="hidden md:block text-body-md font-normal">
            {limitChar(blog.Description)}
          </p>
        </Link>
      </div>
    </div>
  );
};

export const FeaturedBlog = (blog: IBlog) => {
  let url = `/blog/${blog.CustomUrl ?? MakeSlug(blog.Title)}`;

  if (blog.Category) {
    url = `/blog/${MakeSlug(blog.Category.toLowerCase().replace(/_/g, '-'))}/${blog.CustomUrl ?? MakeSlug(blog.Title)}`;
  }

  if (blog.Category === "Conseil_et_fiches_pratiques") {
    url = `/blog/conseils/${blog.CustomUrl ?? MakeSlug(blog.Title)}`;
  }

  if (blog.Category === "Edito_et_actus") {
    url = `/blog/edito/${blog.CustomUrl ?? MakeSlug(blog.Title)}`;
  }

  return (
    <div className="flex flex-col md:flex-row md:h-[380px]">
      <Link to={url} className="no-underline">
        <GatsbyImage
          image={blog.PrimaryImage?.file?.childImageSharp.gatsbyImageData}
          alt={blog.PrimaryImage?.alternativeText || ''}
          className="w-full h-[190px] md:h-full md:max-w-[550px]] rounded" />
      </Link>

      <div className="flex flex-col md:px-[50px] justify-center gap-3 ">
        <div className="rounded-full w-fit font-MW font-bold text-[10px] leading-[12.5px] text-white px-[8px] py-[3px] mt-[20px] md:mt-0"
          style={{ backgroundColor: ColourCategoryMap[blog.Category]?.hex || ColourCategoryMap.default.hex }}>
          {blog.Category && blog.Category.toUpperCase().replaceAll("_", " ")}
        </div>

        <Link to={url} className="group no-underline text-placeholder hover:text-purple cursor-pointer">
          <h3 className="h3-default text-black group-hover:underline">
            {limitChar(blog.Title, 52)}
          </h3>
          <p className="text-body-md text-placeholder font-normal">
            {limitChar(blog.Description)}
          </p>
        </Link>
      </div>
    </div>
  );
};

export const CategoryButton = ({ label, active, onClick }: any) => {
  return (
    <div className={`md:min-w-fit py-[17px] px-[30px] rounded cursor-pointer
      ${active === label ? "bg-purple text-white" : "border-[1px] border-[#E2E2E2]"}`}
      onClick={() => onClick(label)}>

      <p className="text-nowrap">{label.replaceAll("_", " ")}</p>
    </div>
  );
};