import React from "react";
import MaxContent from "../../layout/MaxContent";
import Markdown from "../Markdown/Markdown";
import Button from "../Inputs/Button";
import { IImage } from "../Interfaces";
import { getTitleHTMLTag } from "../../helpers/tools";

interface StrapiData {
  data: PolicyProps;
}

interface PolicyProps {
  Cards: PolCardProps[];
}

interface PolCardProps {
  Title: string;
  Text: string;
  CardColour: string;
  SingleImage: IImage;
  PrimaryButtonLabel?: string;
  PrimaryButtonLink?: string;
  PrimaryButtonNoFollow?: boolean;
  PrimaryButtonNewTab?: boolean;
  SecondaryButtonLabel?: string;
  SecondaryButtonLink?: string;
  SecondaryButtonNoFollow?: boolean;
  SecondaryButtonNewTab?: boolean;
  PolicyTitleType: string;
}
//cs removed max height and added condition image if url is not null
const PolicyCards: React.FC<StrapiData> = ({ data }) => {
  return (
    <MaxContent size="content">
      <div className="flex flex-col lg:flex-row  gap-3 my-[50px] lg:my-[80px]">
        {data.Cards.map((card) => {
          return <PolCard {...card} />;
        })}
      </div>
    </MaxContent>
  );
};

const PolColourMap: Record<string, string> = {
  yellow: "#FFDB33",
  orange: "#FF6B00",
  pink: "#F5C4DC",
  purple: "#423EF4",
  blue: "#0000FF",
  green: "#018565",
};

const PolCard: React.FC<PolCardProps> = ({
  Title,
  Text,
  CardColour: Colour,
  SingleImage,
  PrimaryButtonLabel,
  PrimaryButtonLink,
  SecondaryButtonLabel,
  SecondaryButtonLink,
  PrimaryButtonNewTab,
  PrimaryButtonNoFollow,
  SecondaryButtonNewTab,
  SecondaryButtonNoFollow,
  PolicyTitleType,
}) => {
  const getTextColour = () => {
    let textColour;

    switch (Colour) {
      case "blue":
      case "purple":
      case "green":
        textColour = "text-white";
        break;
      case "pink":
      case "yellow":
      case "orange":
        textColour = "text-black";
      default:
        textColour = "text-black";
    }
    return textColour;
  };

  let title = getTitleHTMLTag(
    Title,
    PolicyTitleType,
    `h2-default font-DM ${getTextColour()} mb-[20px]`
  );
  return (
    <div
      className={`flex flex-col items-start justify-start w-full  py-[70px] px-[50px] rounded-md polCard--${Colour}`}
    >
      {SingleImage?.file?.url && (
        <div className="flex  mx-auto justify-end  ">
          <img
            src={SingleImage?.file?.url}
            className="mb-[30px] w-[100px] h-[100px] md:w-[120px] md:h-[120px]"
            alt={SingleImage?.alternativeText}
          />
        </div>
      )}
      <div>
        {Title && title}
        {Text && (
          <div
            id="policyCardText"
            className={`${getTextColour()} text-body-sm md-content`}
          >
            <Markdown>{Text}</Markdown>
          </div>
        )}
      </div>

      {PrimaryButtonLabel && (
        <div className="mt-4 w-full">
          <Button
            label={PrimaryButtonLabel}
            href={PrimaryButtonLink ?? "/"}
            noFollow={PrimaryButtonNoFollow}
            newTab={PrimaryButtonNewTab}
          />
        </div>
      )}
      {SecondaryButtonLabel && (
        <div className="mt-4 w-full">
          <Button
            label={SecondaryButtonLabel}
            href={SecondaryButtonLink ?? "/"}
            secondary={true}
            noFollow={SecondaryButtonNoFollow}
            newTab={SecondaryButtonNewTab}
          />
        </div>
      )}
    </div>
  );
};

export default PolicyCards;
