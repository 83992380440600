import React from "react";

interface ButtonInterface {
  label: string;
  disabled?: boolean;
  onClick: () => void;
  secondary?: boolean;
  secondaryWithoutHover?: boolean;
}

// Temp: CTA Button stripped from GL Journey, dont use this if possible; use the component from
// import { Button } from 'get-life-ts-components'
const Button: React.FC<ButtonInterface> = ({
  label,
  disabled,
  onClick,
  secondary,
}) => {
  const primaryStyle =
    "bg-purple text-white  border border-purple hover hover:bg-white  hover:text-purple ";
  const secondaryStyle =
    "bg-white  text-purple border border-purple hover:border-white hover hover:bg-purple hover:text-white";
  return (
    <div className="flex ">
      <input
        type="button"
        value={label}
        disabled={disabled}
        onClick={onClick}
        className={`cursor-pointer disabled:bg-gray-300  disabled:border-gray-300 
        h-[60px] flex-grow text-[18px] font-bold opacity-100 transition-all hover:opacity-90  py-[18.5px] rounded-md 
        ${" "}
        ${secondary ? secondaryStyle : primaryStyle} 
        `}
      />
    </div>
  );
};

export default Button;
