import React from 'react'
import MaxContent from '../../layout/MaxContent'


interface StrapiData {
  data: {
    Title: string
  }
}

const Title: React.FC<StrapiData> = ({ data }) => {
  return (  
    <MaxContent size={ 'content' }>
      <h3 className='h3-default text-purple mb-[20px]'>{data.Title}</h3>
    </MaxContent> 
  )
}

export default Title