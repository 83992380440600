import React, { useState, useEffect, RefObject, useLayoutEffect } from "react";

export const useDevice = () => {
  const [width, setWidth] = useState<number>(
    typeof window !== "undefined" ? window.innerWidth : 0
  );

  const isMobile: boolean = width <= 768;
  const isMobileTablet: boolean = width <= 1023;
  const isTablet: boolean = width > 769 && width <= 1150;
  const isDesktop: boolean = width > 1150;
  const isTabletLandscape: boolean = width >= 1023 && width <= 1150;

  function handleWindowSizeChange() {
    if (typeof window !== "undefined") setWidth(window.innerWidth);
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleWindowSizeChange);
      return () => window.removeEventListener("resize", handleWindowSizeChange);
    }
  }, []);

  return {
    isMobile,
    isMobileTablet,
    isTablet,
    isDesktop,
    isTabletLandscape,
  } as const;
};

type TBreadCrumb = {
  name: string;
  link: string;
};

export const useBreadCrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<Array<TBreadCrumb>>([]);

  useEffect(() => {
    const url: string = typeof window !== "undefined" ? window.location.pathname : "";
    const homeTranslation: string = url.includes('/en/') ? 'Home' : 'Accueil' // ES: Inicio, FR: Accueil, EN: Home
    const newBreadCrumbs: Array<TBreadCrumb> = [{ name: homeTranslation, link: "/" }]; 

    const parts = url.split("/");
    let cumulativeLink = "/";
    for (let index = 0; index < parts.length; index++) {
      const element = parts[index];
      if (element === "") {
        continue;
      } else {
        const noDash = element.replaceAll("-", " ").replaceAll('_',' ');
        const label = noDash[0].toUpperCase() + noDash.substring(1);
        cumulativeLink = cumulativeLink + element + "/";
        newBreadCrumbs.push({
          name: label,
          link: cumulativeLink,
        });
      }
    }

    let removedLocaleCrumb = newBreadCrumbs.filter(
      (crumb: TBreadCrumb) => crumb.link !== "/en/"
    );
    removedLocaleCrumb = removedLocaleCrumb.filter(
      (crumb: TBreadCrumb) => crumb.link !== "/fr/"
    );

    // formats blog pages correctly
    let hasPage = removedLocaleCrumb.filter( brdc => brdc.name === 'Page' ).length > 0
    if (hasPage) {
      removedLocaleCrumb = removedLocaleCrumb.filter( brdc => brdc.name !== 'Page' )

      // Blog Home
      if(removedLocaleCrumb.length === 3) {
        if(removedLocaleCrumb[2].name) {
          removedLocaleCrumb[2].name = `Page ${removedLocaleCrumb[2].name}`
        }
      }

      // Blog Category
      if(removedLocaleCrumb.length === 4) {
        if(removedLocaleCrumb[3].name) {
          removedLocaleCrumb[3].name = `Page ${removedLocaleCrumb[3].name}`
        }
      }      
    }

    setBreadcrumbs(removedLocaleCrumb);
  }, []);

  return { breadcrumbs };
};

// https://stackoverflow.com/a/65008608
// export function useOnScreen(ref: RefObject<any>) {
//   const [isIntersecting, setIntersecting] = useState(false)
//   const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

//   useEffect(() => {
//     observer.observe(ref.current)
//     // Remove the observer as soon as the component is unmounted
//     return () => { observer.disconnect() }
//   }, [])

//   return isIntersecting
// }

// https://usehooks.com/useOnScreen/
export function useOnScreen(ref: RefObject<any>, rootMargin = "0px") {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}
