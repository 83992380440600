import React from "react";

interface ContainerProps {
  children: React.ReactNode;
  size: "full" | "max" | "content" | "content-inner" | 'responsive'
  className?: string;
}

const MaxContent = ({ children, size, className }: ContainerProps) => {
  const getSize = () => {
    switch (size) {
      case "full":
        return "";
      case "max":
        return "max-w-max";
      case "content":
        return "max-w-content px-content xl:px-0";
      case "content-inner":
        return "max-w-contentInner px-content";
      case "responsive":
        return "max-w-max md:content";
      default:
        return "";
    }
  };

  const sizeStyle = getSize();

  return (
    <section
      className={`mx-auto w-full 
    ${sizeStyle} 
    ${className || ""}`}
    >
      {children}
    </section>
  );
};

export default MaxContent;
