import React from 'react'
import MaxContent from '../../layout/MaxContent'
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from 'get-life-ts-components'
import { StrapiImage } from '../Types'

interface Navigation_HeaderData {
  data: Navigation_HeaderProps
}

interface Navigation_HeaderProps {
  Title: string
  Nav: string
  Location: string
  ButtonsItem: Array<ButtonProps>
  Icon: StrapiImage
}

const Navigation_Header = ({ data }: Navigation_HeaderData) => {
   
return (
  <MaxContent size="max">
    <div className="bg-inactive-gray w-full ">
      <MaxContent size="content">
        <div className="md:grid-cols-2 md:grid flex flex-col-reverse bg-inactive-gray">
          <div className="text-left	md:w-9/12 mt-12">
            <p className="text-[14px] leading-[20px] font-normal text-placeholder mb-3 mt-5 w-auto">{ data.Nav } <span className="font-black">{ data.Location }</span></p>
            <h1 className="text-[28px] leading-[38px] lg:text-[40px] lg:leading-[50px] font-bold text-purple mb-3 w-full md:w-auto mt-8">{ data.Title } </h1>
            <div className="">
              { data?.ButtonsItem.map( (item: { Label: string; Url: string; Alt: string }) => <Buttons Label={item.Label} Url={item.Url} Alt={item.Alt} />) }
            </div>
          </div>
        
          <div className="flex items-center">
            <GatsbyImage
              image={data.Icon?.file.childImageSharp.gatsbyImageData}
              alt={data.Icon?.alternativeText}
              className=""/>    
          </div>
        </div>
      </MaxContent>
    </div>
  </MaxContent>
  )
}
interface ButtonProps {
  Label: string,
  Url: string,
  Alt: string,
}
  
const Buttons: React.FC<ButtonProps> = ({
  Label,
  Url,
  Alt,
}) => {
  return ( 
    <div className="w-full md:max-w-[325px] mt-5 space-y-4">
            <Button label={Label }  onClick={() => {} } secondary={true} />
       
    </div>
  )
}
export default Navigation_Header;
