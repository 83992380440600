import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState, useEffect, useRef } from "react";
import MaxContent from "../../layout/MaxContent";
import Button from "../Inputs/Button";
import { navigate } from "gatsby";
import { a, useSpring, useTransition, config } from "react-spring";
import GatsbyLink from "gatsby-link";
import { IImage, IButton, ILink } from "../Interfaces";
import Markdown from "../Markdown/Markdown";
import { getTitleHTMLTag } from "../../helpers/tools";

interface StrapiData {
  data: AITProps;
}

interface AITProps {
  Title: string;
  SelectTitleType: any;
  SubText: string;
  BulletPoints: Array<BulletPoint>;
  Configuration: "Left" | "Right";
  VideoURL: string;
  PrimaryMedia: IImage;
  Link: ILink;
  Buttons: Array<IButton>;
}

interface BulletPoint {
  id: string;
  Title: string;
  Text: string;
  Image: IImage;
}

const AnimatedImageText: React.FC<StrapiData> = ({ data }) => {
  let { Title, SubText, Link, BulletPoints, Buttons, PrimaryMedia, VideoURL } =
    data;

  const images: Array<IImage> = BulletPoints.map((bp) => {
    return bp.Image;
  });
  const config = data?.Configuration || "Left";
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [playAnimation, setPlayAnimation] = useState<Boolean>(false);
  let title = getTitleHTMLTag(
    Title,
    data.SelectTitleType?.TitleTypeSelection,
    "h2-default font-bold text-purple mb-3"
  );

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (playAnimation) {

      interval = setInterval(() => {
        if (activeIndex === BulletPoints.length - 1) {
          setActiveIndex(0);
        } else {
          setActiveIndex(activeIndex + 1);
        }
      }, 4000);

    }

    return () => clearInterval(interval);
  }, [activeIndex, playAnimation]);

  const getImage = () => {
    if (VideoURL) {
      return (
        // muted is required for autoPlay to work in some browsers
        <video
          className="w-full h-full"
          preload={"auto"}
          controls
          autoPlay
          playsInline
          muted
          loop
        >
          <source src={VideoURL} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    if (PrimaryMedia) {
      if (PrimaryMedia.file.extension === "svg") {
        return (
          <img src={PrimaryMedia?.file?.url} className="transition h-full" alt={PrimaryMedia?.alternativeText || ""}/>
        );
      } else {
        return (
          <GatsbyImage
            className="transition w-full max-h-[320px] md:min-h-[320px]"
            image={PrimaryMedia?.file?.childImageSharp?.gatsbyImageData}
            alt={PrimaryMedia?.alternativeText || ""}
          />
        );
      }
    } else if (BulletPoints[activeIndex]) {
      return (
        <AnimatedImages
          images={images}
          index={activeIndex}
          setPlayAnimation={setPlayAnimation}
        />
      );
    }
  };

  return (
    <MaxContent size="content">
      <div className="md:grid flex flex-col md:grid-cols-2 mb-[70px] md:my-[100px] pt-8 md:pt-0">
        {/* Col 1 */}
        <div
          className={`flex flex-col justify-center ${config === "Right" ? "pl-0 md:pl-[50px]" : "pr-0 md:pr-[50px]"
            }`}
        >
          {Title && title}

          {SubText && (
            <div className="text-[16px] leading-[26px] lg:text-[18px] lg:leading-[28px] mt-5 md-content">
              <Markdown>{SubText}</Markdown>
            </div>
          )}

          <ul className="flex flex-col gap-3 ">
            {BulletPoints &&
              BulletPoints.map((point: BulletPoint, index: number) => (
                <ListItem
                  text={point.Text}
                  key={point.Text}
                  id={index}
                  active={activeIndex}
                  setPlayAnimation={setPlayAnimation}
                  playAnimation={playAnimation}
                />
              ))}
          </ul>

          {Link?.Label && (
            <GatsbyLink
              to={`${Link?.Url}`}
              className="text-link-md no-underline hover:underline text-purple mt-[40px]"
            >
              {Link?.Label}
            </GatsbyLink>
          )}

          <div className="flex flex-col gap-3 max-w-[325px] mt-[15px]">
            {Button &&
              Buttons.map((button: IButton) => {
                return (
                  <Button
                    key={button?.Url}
                    label={button?.Label}
                    href={button?.Url ?? "/"}
                    secondary={button?.Secondary}
                    noFollow={button?.NoFollow ?? false}
                    newTab={button?.NewTab ?? false}
                  />
                );
              })}
          </div>
        </div>

        {/* Col 2 */}
        <div
          className={`relative object-contain mb-4 h-[320px] md:h-full 
            flex justify-center items-center md:overflow-hidden 
            ${config === "Right" ? "order-first" : "order-first md:order-none"
            }`}
        >
          {getImage()}
        </div>
      </div>
    </MaxContent>
  );
};

const AnimatedImages: React.FC<any> = ({ images, index, playAnimation }) => {
  // const [index, setIndex] = useState(0);

  const transitions = useTransition(images[index], {
    config: { ...config.molasses, duration: 600 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    immediate: true,
    expires: false, // this stops the transition item from unmounting, which prevents reactDOM from redownloading images
  });

  return transitions(
    (styles, item) =>
      item && (
        <a.div style={styles} className="absolute">
          <img
            loading="lazy"
            src={item.file.url}
            className="w-full max-h-[320px] md:min-h-[320px]"
          />
        </a.div>
      )
  );
};

interface LIProps {
  text: string;
  id: number;
  active: number;
  setPlayAnimation: (value: Boolean) => void;
  playAnimation: Boolean;
}

export const ListItem: React.FC<LIProps> = ({
  text,
  id,
  active,
  setPlayAnimation,
  playAnimation,
}) => {
  const ref = useRef<any>(null);

  let selected = id === active;

  const springStyle = useSpring({
    config: {
      duration: 4000,
    },
    from: { height: "0%" },
    to: { height: "100%" },
    reset: true,
    loop: true,
  });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];

      if (entry && entry.isIntersecting) {
        setPlayAnimation(true);
        observer.unobserve(ref.current);
      }
    });

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div className={`flex flex-row gap-[15px]`} ref={ref}>
      <div className="relative">
        {selected && (
          <a.div
            style={springStyle}
            className="absolute bg-purple min-w-[3px]"
          />
        )}
        <div className="absolute bg-purple opacity-20 min-h-full min-w-[3px]" />
      </div>

      <div
        className={`text-body-md ${selected ? "" : "transition opacity-20"}`}
      >
        {text}
      </div>
    </div>
  );
};

export default AnimatedImageText;
