import React, { useEffect, useState, useRef } from "react";
import { ILink } from "../Interfaces";
import { navigate } from "gatsby";
import { useDevice } from "../Hooks";
import Button from "../Inputs/Button";
import GatsbyLink from "gatsby-link";
import LocaleToggle from "../LocaleToggle/LocaleToggle";
import { StrapiImage } from "../Types";
import { clickButton } from "../../helpers/tools";

interface StrapiData {
  data: NavProps;
}

interface NavProps {
  DropdownLabel: string;
  DropdownLinks: Array<ILink>;
  Links: Array<ILink>;
  Phone?: string;
  HideAccount?: boolean;
  AccountIcon: StrapiImage;
  AccountUrl: string;
  AccountLabel: string;
  AccountNoFollow?: boolean;
  AccountNewTab?: boolean;
  ButtonLabel: string;
  ButtonUrl: string;
  ButtonNoFollow?: boolean;
  ButtonNewTab?: boolean;
}

const Navbar: React.FC<StrapiData> = ({ data }) => {
  const [openDropdown, setDropdown] = useState<boolean>(false);
  const [openMoreDropdown, setOpenMoreDropdown] = useState<boolean>(false);
  const [openMobileMenu, setMobileMenu] = useState<boolean>(false);
  const { isMobileTablet, isTabletLandscape } = useDevice();
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    let slug = window.location.pathname;
    setUrl(slug);
  });

  const {
    DropdownLabel,
    DropdownLinks,
    Links,
    Phone,
    HideAccount,
    AccountIcon,
    AccountUrl,
    AccountLabel,
    AccountNoFollow,
    AccountNewTab,
  } = data;

  return (
    <nav className="sticky top-0 bg-white  w-full gap-3  lg:drop-shadow-lg lg:opacity-95 border-b border-b-neutral-200 z-50">
      <div className="nav-bar flex flex-row mx-auto h-[100px] px-[25px] md:px-[50px]">
        <div className="flex grow items-center">
          <GatsbyLink to="/" className="flex items-center">
            <img src={"https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.svg"} className=" h-[24px] md:w-[137px] md:h-[40px]" />
          </GatsbyLink>
        </div>

        {/* Desktop */}

        <div className="hidden lg:flex items-center overflow-hidden flex-wrap ">
          {/* Dropdown */}
          <div className="flex items-center h-full">
            <div
              className="flex items-center relative hover:border-b-2 hover:pt-[2px] border-purple h-full cursor-pointer"
              onMouseOver={() => setDropdown(true)}
              onMouseOut={() => setDropdown(false)}
            >
              <a
                className={`flex flex-row items-center 
                gap-3 px-5 no-underline whitespace-nowrap text-link-sm font-medium border-purple h-full
                ${
                  url.includes(DropdownLabel?.toLowerCase()) &&
                  !url.includes("blog")
                    ? "border-b-2 pt-[2px]"
                    : "hover:border-b-2 hover:pt-[2px]"
                }`}
              >
                {DropdownLabel}
                <img
                  src={"https://storage.googleapis.com/getlife-bucket-1/public/image/cms/open-chevron.svg"}
                  className={`${openDropdown ? "" : "rotate-180"}`}
                  alt="chevron"
                />
              </a>
            </div>

            {/* Dropdown Body */}
            {openDropdown && (
              <div
                className="flex flex-col absolute top-full bg-purple z-100"
                onMouseOver={() => setDropdown(true)}
                onMouseOut={() => setDropdown(false)}
              >
                {DropdownLinks.map((link) => (
                  <GatsbyLink
                    to={link.Url}
                    className="no-underline text-white px-5 py-4 text-link-sm font-medium"
                    key={link.Url + "dropdownLinks"}
                  >
                    {link.Label}
                  </GatsbyLink>
                ))}
              </div>
            )}
          </div>

          {/* Links */}

          {!isTabletLandscape &&
            Links.map((link, i) => (
              <NavItem link={link} url={url} key={i + "Links"} />
            ))}
        </div>

        {isTabletLandscape && DropdownLinks.length > 0 && (
          <div
            className="flex items-center h-full mr-4"
            onMouseOver={() => setOpenMoreDropdown(true)}
            onMouseOut={() => setOpenMoreDropdown(false)}
          >
            <div
              className={`flex flex-row relative h-full items-center hover:border-b-2 hover:pt-[2px] border-purple cursor-pointer ${Links.map(
                (link) => url.includes(link.Url?.toLowerCase())
              )}`}
            >
              <a
                className="px-[10px]  flex flex-row items-center 
                gap-3 text-[16px] leading-[26px] font-medium h-full no-underline m-0"
              >
                Suite
                <img
                  src={"https://storage.googleapis.com/getlife-bucket-1/public/image/cms/open-chevron.svg"}
                  className={`${openMoreDropdown ? "" : "rotate-180"}`}
                  alt="chevron"
                />
              </a>
            </div>

            {openMoreDropdown && DropdownLinks.length > 0 && (
              <div className="flex flex-col absolute top-full bg-purple z-100">
                {Links.map((link, i) => (
                  <GatsbyLink
                    to={link.Url}
                    key={link.Url + i + "OpenMore"}
                    className="no-underline text-white px-5 py-4 text-link-sm font-normal"
                  >
                    {link.Label}
                  </GatsbyLink>
                ))}
              </div>
            )}
          </div>
        )}

        {/* CTA Section */}
        <div className="flex items-center gap-[10px] shrink">
          <div className="flex items-center">
            {data.ButtonLabel && (
              <button
                onClick={() => clickButton({ href: data.ButtonUrl, navigate })}
                className="flex items-center bg-purple text-white border border-purple hover:bg-white hover:text-purple  nav-btn md:text-legal-small min-w-[80px] h-[18px] md:w-fit md:h-[48px]"
              >
                {data.ButtonLabel}
              </button>
            )}
          </div>

          {Phone && (
            <a
              href={`tel:/${Phone}`}
              className="hidden lg:flex lg:flex-row items-center whitespace-nowrap text-legal-small no-underline"
            >
              <img
                src={"https://storage.googleapis.com/getlife-bucket-1/public/image/cms/phone-icon.svg"}
                className="w-[26px] h-[26px] sm:w-[36px] sm:h-[36px] lg:mr-[5px]"
                alt="chevron"
              />
              {Phone}
            </a>
          )}

          {AccountIcon && AccountUrl && !isMobileTablet && !HideAccount && (
            <a href={AccountUrl} target="_blank">
              <img
                src={AccountIcon?.file?.url}
                alt={AccountIcon?.alternativeText}
                className="w-[26px] h-[26px] md:w-[36px] md:h-[36px]"
              />
            </a>
          )}

          {DropdownLinks.length > 0 && (
            <img
              src={openMobileMenu ? "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/blue-cross.svg" : "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/burger-menu.svg"}
              className="block lg:hidden w-[22px] h-[25px]"
              onClick={() => setMobileMenu(!openMobileMenu)}
              alt="burguer"
            />
          )}
        </div>

        {/* Mobile Menu Overlay */}
        {isMobileTablet && openMobileMenu && DropdownLinks.length > 0 && (
          <div className="fixed top-0 left-0 w-full h-full z-10 translate-y-[100px] bg-white px-6">
            <div className="flex flex-col">
              {/* Mobile Dropdown Label */}
              <a
                onClick={() => setDropdown(!openDropdown)}
                className={`flex flex-row justify-between no-underline 
              border-b border-b-legal-gray py-5 
              font-bold text-[16px] leading-[26px] 
              ${openDropdown ? "border-t border-t-purple" : "text-black"}
              `}
              >
                {DropdownLabel}
                <img
                  className="w-4"
                  src={openDropdown ? "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/blue-chevron.svg" : "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/black-chevron.svg"}
                  alt="logo"
                />
              </a>

              {/* Mobile Dropdown Body */}
              {openDropdown && DropdownLinks.length > 0 && (
                <div className="flex flex-col border-b border-b-legal-gray ">
                  {DropdownLinks.map((link) => (
                    <GatsbyLink
                      to={link.Url}
                      className="no-underline font-bold text-[16px] leading-[26px] p-3"
                      key={link.Url + "opendropdown"}
                    >
                      {link.Label}
                    </GatsbyLink>
                  ))}
                </div>
              )}

              {Links.map((link) => (
                <GatsbyLink
                  to={link.Url}
                  className="no-underline font-bold text-[16px] leading-[26px] text-black border-b border-b-legal-gray py-5"
                  key={link.Label}
                >
                  {link.Label}
                </GatsbyLink>
              ))}

              {/* Temp Region Selctor goes here */}
              <a className="no-underline text-white border-b border-b-legal-gray py-5">
                <LocaleToggle />
                {DropdownLabel}
              </a>

              {data.ButtonLabel && (
                <Button
                  label={data.ButtonLabel}
                  href={data.ButtonUrl}
                  noFollow={data.ButtonNoFollow}
                  newTab={data.ButtonNewTab}
                />
              )}
              <div className="mb-3" />
              {!HideAccount && (
                <Button
                  label={AccountLabel || ""}
                  href={AccountUrl || "/"}
                  secondary={true}
                  noFollow={AccountNoFollow}
                  newTab={AccountNewTab}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

const NavItem = ({ link, url }: { link: ILink; url: string }) => {
  const ref = useRef(null);
  // const isVisible = useOnScreen(ref)

  return (
    <div
      ref={ref}
      className={`flex items-center ${
        url === link.Url
          ? "border-b-2 pt-[2px]"
          : "hover:border-b-2 hover:pt-[2px]"
      } border-purple h-full`}
    >
      <GatsbyLink
        to={link.Url}
        className="px-2 xl:px-5 text-link-sm no-underline font-medium whitespace-nowrap cursor-pointer"
      >
        {link.Label}
      </GatsbyLink>
    </div>
  );
};

export default Navbar;
