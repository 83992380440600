import React from 'react'
import { useDevice } from '../Hooks'
import MaxContent from "../../layout/MaxContent";
import { GatsbyImage } from 'gatsby-plugin-image';
import { StrapiImage } from '../Types'

interface ImageBannerData {
  data: ImageBannerprops
}

interface ImageBannerprops {
  DesktopImage: StrapiImage
}

const ImageBanner= ({ data }: ImageBannerData) => {
  return (
    <MaxContent size="max">
      <div className="">
      <MaxContent size="max">
        <section className="md:h-full flex flex-col justify-around">
          <GatsbyImage 
            image= {data.DesktopImage?.file?.childImageSharp.gatsbyImageData}
            alt={data.DesktopImage?.alternativeText}
            className="" 
            />       
        </section>
        </MaxContent>
      </div>
    </MaxContent>
)}

export default ImageBanner;


