import React from "react";
import { Button as GL_Button } from "get-life-ts-components";
import { navigate } from "gatsby";
import MaxContent from "../../layout/MaxContent";
import { clickButton } from "../../helpers/tools";

interface StrapiData {
  data: {
    Label: string;
    Url: string;
    Secondary: boolean;
  };
}

const Button: React.FC<StrapiData> = ({ data }) => {
  return (
    <MaxContent size="content">
      <div className="max-w-[325px]">
        <GL_Button
          label={data.Label}
          onClick={() => {
            clickButton({ href: data.Url, navigate });
          }}
          secondary={data.Secondary}
        />
      </div>
    </MaxContent>
  );
};

export default Button;
