import React, { useState } from "react";
import { a, useSpring } from "react-spring";
import Button from "../Inputs/Button";
import MaxContent from "../../layout/MaxContent";
import { IButton } from "../Interfaces";
import { getTitleHTMLTag } from '../../helpers/tools'
import Markdown from '../Markdown/Markdown'

const plus = "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/plus.svg";
const minus = "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/minus.svg"

interface StrapiData {
  data: AccordionProps;
}

interface AccordionProps {
  Title?: string;
  SelectTitleType: any;
  Items: Array<AccordianItemProps>;
  Button?: IButton;
}

const Accordion: React.FC<StrapiData> = ({ data }) => {
  const [openIndex, setOpenIndex] = useState<number>(-1);

  function handleClick(i: number) {
    if (i === openIndex) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(i);
    }
  }

  let title = getTitleHTMLTag(data.Title!, data.SelectTitleType?.TitleTypeSelection, "container mx-auto font-bold text-purple text-[28px] leading-[38px] mt-[60px] mb-4 p-4 md:p-0")

  return (
    <MaxContent size="content">
      <div className="mt-[50px] mb-[90px]">
        <div className="bg-purple h-[5px] w-full" />

        {data.Title && title}
        <div className="flex flex-col items-center gap-3">
          {/* Accordian */}
          <div className="container mx-auto">
            {data.Items.map((item, index) => (
              <AccordionItem
                Title={item.Title}
                TitleType={item.TitleType}
                key={item.Title}
                Text={item.Text}
                onClick={() => handleClick(index)}
                open={index === openIndex}
              />
            ))}
          </div>

          {data.Button && (
            <div className="md:w-1/4 w-[325px] mt-[30px]">
              {/* @ts-ignore */}
              <Button
                label={data.Button.Label}
                secondary={true}
                href={data.Button?.Url ?? "/"}
                noFollow={data.Button?.NoFollow ?? false}
                newTab={data.Button?.NewTab ?? false}
              />
            </div>
          )}
        </div>
      </div>
    </MaxContent>
  );
};


interface AccordianItemProps {
  Title: string;
  Text: string;
  TitleType: string;
  open: boolean;
  onClick: () => void;
}

const AccordionItem: React.FC<AccordianItemProps> = ({
  Title,
  Text,
  TitleType,
  onClick,
  open,
}) => {
  const openAnimation = useSpring({
    config: { duration: 300 },
    display: open ? "block" : "none",
    opacity: open ? 1 : 0,
  });

  let title = getTitleHTMLTag(Title, TitleType!, 'font-bold text-[18px] leading-[30px]')

  return (
    <div className={`py-6 border-y ${open ? "border-b-purple" : ""}`} onClick={() => onClick()}>
      <div className="flex flex-row items-center justify-between w-full text-black cursor-pointer">
        {title}
        <img src={open ? minus : plus} className="w-5 h-5 cursor-pointer" alt="Icon accordion"/>
      </div>

      <a.div style={openAnimation} className="mt-3">
        {Text && (
          <div className="text-[18px] leading-[28px] text-placeholder">
            <Markdown>{Text}</Markdown>
          </div>
        )}
      </a.div>
    </div>
  );
};

export default Accordion;
