import React, {
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "gatsby";
import MaxContent from "../../layout/MaxContent";
import MakeSlug from "../../helpers/makeSlug";
import Markdown from "../Markdown/Markdown";
import { useDevice } from "../Hooks";
import { getTitleHTMLTag } from "../../helpers/tools";
import useFaqSearch from "../../helpers/useFaqSearch";

interface StrapiData {
  data: {
    Title: string;
    SubText: string;
    SelectTitleType: {
      TitleTypeSelection: string;
    };
    SearchLabel: string;
    NoResultLabel: string;
    Faqs: IFAQ[];
    CustomUrl: string;
  };
}

export interface IFAQ {
  id: string;
  Title: string;
  Category: string;
  CustomUrl: string;
}

const Faq: React.FC<StrapiData> = ({ data }) => {
  // memoised function which reduces FAQ Questions into their respective categorys
  const Categories = useMemo(
    () =>
      data.Faqs?.reduce((acc: Map<any, Array<any>>, next: IFAQ) => {
        const category = acc.get(next.Category);

        if (!category) {
          acc.set(next.Category, [{ title: next.Title, url: next.CustomUrl }]);
        } else {
          category.push({ title: next.Title, url: next.CustomUrl });
        }
        return acc;
      }, new Map()),
    []
  );

  const [activeCategory, setActiveCategory] = useState<string>(
    [...Categories.keys()][0] ?? ""
  );
  const [input, setInput] = useState<string>("");
  const { search } = useFaqSearch(data.Faqs);
  const [searchResults, setSearchResults] = useState<Array<IFAQ>>();
  const [error, setError] = useState<boolean>(false);

  const title = getTitleHTMLTag(
    data.Title,
    data.SelectTitleType?.TitleTypeSelection,
    "h2-default text-purple"
  );

  const content = useMemo(() => {
    const faqContent: React.ReactElement[] = [];

    Categories.forEach((value, key) => {
      faqContent.push(
        <div
          key={key}
          className={
            activeCategory !== key ? "hidden" : "flex flex-col gap-[30px] pt-4"
          }
        >
          {value.map((question) => {
            return (
              <Link
                to={`${`/faq/${
                  question.url ?? MakeSlug(question.title)
                }/`.replace(/\/\//g, "/")}`}
                className="text-body-md no-underline font-normal text-black hover:text-purple hover:underline"
              >
                {question.title}
              </Link>
            );
          })}
        </div>
      );
    });

    return faqContent;
  }, [activeCategory]);

  useEffect(() => {
    if (Categories) {
      let categoryKeys = [...Categories.keys()];

      if (categoryKeys.length > 0) {
        setActiveCategory(categoryKeys[0]);
      }
    }
  }, []);

  const handleClick = (e: any) => {
    const queryResults = search(input);

    if (queryResults.length === 0) {
      setError(true);
      setSearchResults(undefined);
    } else {
      setSearchResults(queryResults);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const queryResults = search(input);

      if (queryResults.length === 0) {
        setError(true);
        setSearchResults(undefined);
      } else {
        setSearchResults(queryResults);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);

    if (e.target.value === "") {
      setError(false);
      setSearchResults(undefined);
    }
  };

  return (
    <MaxContent size="content">
      <div className="flex flex-col  justify-center items-center  mb-[40px]">
        <div className="flex flex-col w-full  items-center">
          {data.Title && title}

          {data.SubText && (
            <div className="font-MW md:text-center text-[16px] leading-[26px] md:text-[20px] md:leading-[30px] md-content">
              <Markdown>{data.SubText}</Markdown>
            </div>
          )}

          {/* searchbar */}
          <div className="w-full md:w-auto relative mb-[20px] md:mt-[30px]">
            <input
              className="flex flex-row justify-between items-center p-3 border border-gray-2 w-full md:w-[550px] rounded-md bg-[#F5F7FA]"
              placeholder={data.SearchLabel}
              type={"text"}
              onChange={(e) => handleChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
            <img
              src={"https://storage.googleapis.com/getlife-bucket-1/public/image/cms/blue-looking-glass.svg"}
              className="h-5 absolute right-3 top-1/2 -translate-x-1/2 -translate-y-1/2"
              onClick={(e: any) => handleClick(e)}
              alt="LookingGlass"
            />
          </div>

          {data.NoResultLabel && error && (
            <p className="h6-default text-placeholder">{`${data.NoResultLabel} ${input}`}</p>
          )}
        </div>
      </div>

      <div className="grid lg:grid-cols-12 gap-10 mb-[80px]">
        {/* sidebar */}
        {!searchResults && (
          <div className="lg:col-span-4 order-last lg:order-first  h-min">
            {Categories &&
              Array.from(Categories).map(([category, _]) => {
                return (
                  <CategoryTab
                    allCategorys={Categories}
                    category={category}
                    activeCategory={activeCategory}
                    setActiveCategory={setActiveCategory}
                  />
                );
              })}
          </div>
        )}
        {/* sidebar end */}

        {/* desktop faq content */}
        <div className="hidden md:block lg:col-span-8  md:p-0">
          {!searchResults &&
            content.map((element: React.ReactElement) => element)}

          {searchResults && (
            <div className="flex flex-col gap-[30px] pt-4">
              {searchResults.map((faq) => {
                return (
                  <Link
                    to={`${(faq.CustomUrl ?? MakeSlug(faq.Title)).replace(
                      /\/\//g,
                      "/"
                    )}`}
                    className="text-body-md no-underline font-normal text-black hover:text-purple hover:underline"
                  >
                    {faq.Title}
                  </Link>
                );
              })}
            </div>
          )}
        </div>

        {/* Mobile Faq Search Content */}
        <div className="col-span-1 md:hidden">
          <div className="flex flex-col gap-[30px] pt-4">
            {searchResults &&
              searchResults.map((faq) => {
                return (
                  <Link
                    to={`${(faq.CustomUrl ?? MakeSlug(faq.Title)).replace(
                      /\/\//g,
                      "/"
                    )}`}
                    className="text-body-md no-underline font-normal text-black hover:text-purple hover:underline"
                  >
                    {faq.Title}
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </MaxContent>
  );
};

export const CategoryTab = ({
  allCategorys,
  category,
  activeCategory,
  setActiveCategory,
}: any) => {
  const ref = useRef<any>(null);
  const questions = allCategorys.get(category);

  const { isMobileTablet } = useDevice();
  const rotate = isMobileTablet ? "rotate-0" : "rotate-90";

  const scrollIntoView = () => {
    if (!isMobileTablet) {
      return;
    }
    setTimeout(() => {
      const elemHeight = ref.current.scrollHeight + 50; // 50px to ensure element is in view
      window.scrollTo({
        behavior: "smooth",
        top: ref.current.offsetTop - elemHeight,
      });
    }, 0);
  };

  return (
    <div
      key={category}
      className="flex flex-col lg:px-5 first-of-type:pt-6 bg-inactive-gray"
    >
      <div
        className={`flex flex-row items-center justify-between cursor-pointer border-t-2 font-bold text-black py-7 px-[5px] md:px-0 
          border-t${activeCategory === category ? "-2" : ""} 
          border-${activeCategory === category ? "purple" : "legal-gray"}
          ${activeCategory === category ? "text-purple" : ""}`}
        onClick={() => {
          setActiveCategory(category);
          scrollIntoView();
        }}
        ref={ref}
      >
        {category}
        <img
          src={activeCategory === category ? "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/blue-chevron.svg" : "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/black-chevron.svg"}
          className={`pl-4 ${activeCategory === category ? rotate : ""}`}
          alt="LookingGlass"
        />
      </div>

      <div className="px-[5px] lg:hidden flex flex-col bg-white border-t-[1px] border-t-legal-gray lg:px-[20px]">
        {activeCategory === category &&
          questions.map((question: { title: string; url: string }) => {
            let url = `/faq/${
              question.url ?? MakeSlug(question.title)
            }/`.replace(/\/\//g, "/");

            return (
              <Link
                to={url}
                className="text-body-sm no-underline py-[20px] text-black"
              >
                {question.title}
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default Faq;
