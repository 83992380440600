import React from "react";
import MaxContent from "../../layout/MaxContent";
import { StrapiImage } from "../Types";

interface StrapiData {
  data: {
    IconsList: IconType[];
  };
}

type IconType = {
  SingleImage: StrapiImage;
};

const IconsRow: React.FC<StrapiData> = ({ data }) => {
  return (
    //cs changed flex to grid so that its either 6 in row or two rows of three bug250
    <MaxContent size="full">
      <div className="bg-skyblue py-[30px] mx-auto w-full">
        <MaxContent size="content">
          <div className="flex justify-center items-center gap-5 flex-wrap">
           
              {data.IconsList.map((icon: any, index: number) => {
                return (
                  <img 
                    loading="lazy"
                    src={icon?.SingleImage?.file?.url}
                    className=" w-[100px] sm:w-auto h-auto"
                    key={"icon" + index}
                    alt={icon?.SingleImage?.alternativeText}
                  />
                );
              })}
        
          </div>
        </MaxContent>
      </div>
    </MaxContent>
  );
};

export default IconsRow;
