import React, { useState } from "react";
import MaxContent from "../../layout/MaxContent";
import TrustPilotStars from "../TrustPilot/TrustPilotStars";
import TrustPilotWidget from "../TrustPilot/TrustPilotWidget";
// import { Button } from "get-life-ts-components";
import Button from '../Inputs/Button'
import { a, config, useSpring } from "react-spring";
import { getTitleHTMLTag } from "../../helpers/tools";
import { TitleType } from '../Types'

interface BannerData {
  data: BannerProps;
}

interface BannerProps {
  Title: string;
  SubTitle?: string;
  ButtonLabel: string;
  ButtonUrl: string;
  ButtonNoFollow?: boolean;
  ButtonNewTab?: boolean;
  SelectTitleType: {
    TitleTypeSelection: TitleType
  }
  AnimatedBubbles: boolean
}

const PromoBanner: React.FC<BannerData> = ({ data }) => {
  const { Title, SubTitle, ButtonLabel, ButtonUrl, ButtonNoFollow, ButtonNewTab, AnimatedBubbles } = data;

  let title = getTitleHTMLTag(Title, data.SelectTitleType?.TitleTypeSelection, "text-3xl font-bold")

  return (
    <MaxContent size="full">
      <section className="relative flex flex-col justify-around  md:items-center text-left md:text-center text-white bg-purple h-full md:max-h-96 lg:p-20 p-6 gap-3 overflow-hidden">
        <div className="max-w-[800px] mx-auto">
          {Title && <>{title}</>}
          <p className="text-[20px] leading-[30px] font-MW mt-4">{SubTitle}</p>
        </div>
        {
          AnimatedBubbles && (
            <>
              {/* LEFT */}
              <AnimatedBubble x={-650} y={-150} size={80} />
              <AnimatedBubble x={-600} y={-100} size={60} />
              <AnimatedBubble x={-400} y={80} size={30} />
              {/* CENTER */}
              <AnimatedBubble x={-100} y={-150} size={60} />
              <AnimatedBubble x={-100} y={100} size={30} />
              <AnimatedBubble x={10} y={150} size={20} />
              {/* RIGHT */}
              <AnimatedBubble x={600} y={-150} size={50} />
              <AnimatedBubble x={300} y={150} size={80} />
              <AnimatedBubble x={500} y={80} size={45} />
            </>
          )
        }
       
        {ButtonLabel &&
          <div className="w-full mx-auto max-w-[325px] ">
            <Button
              label={ButtonLabel}
              href={ButtonUrl}
              secondary={true}
              noFollow={ButtonNoFollow}
              newTab={ButtonNewTab}
            />
          </div>
        }

        <div className="flex flex-col md:items-center md:flex-row justify-center md:p-3 gap-3 w-full">
          {/* <TrustPilotWidget /> */}

          <div
            className="trustpilot-widget"
            data-locale="es-ES"
            data-template-id="5419b637fa0340045cd0c936"
            data-businessunit-id="610a596be67bea001d95a46c"
            data-style-height="20px"
            data-style-width="100%"
            data-theme="dark"
            data-tags="SelectedReview"
            data-font-family="Merriweather"
          >
            <a
              href="https://es.trustpilot.com/review/getlife.es"
              target="_blank"
              rel="noopener"
              className="text-white no-underline hover:underline"
            >
              Trustpilot
            </a>
          </div>
          <TrustPilotStars />
        </div>
      </section>
    </MaxContent>
  );
};

const AnimatedBubble: React.FC<{ x: number; y: number; size: number }> = ({
  x,
  y,
  size,
}) => {
  const [reverse, setReverse] = useState<boolean>(false);

  const getRand = () => {
    // 50% chance negative, between -100 & 100
    return Math.floor((Math.random() - 0.5) * 100);
  };

  const spring = useSpring({
    config: { ...config.molasses, duration: 2000 + getRand() * 2 },
    from: { transform: `translate3d(${x}px,${y}px,0)` },
    to: [
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x}px,${y}px,0)` },
    ],
    onRest: () => {
      setReverse(!reverse);
    },
    loop: true,
    reverse: reverse,
  });

  return (
    <a.div
      className="absolute rounded-full opacity-20 bg-white"
      style={{ ...spring, width: size, height: size }}
    />
  );
};

export default PromoBanner;
